@import './typography.css';
@import './layout.css';
@import './doctor.css';
@import './my-swiper.css';
@import './fpd.css';
@import './entry-content-bootstrap.css';
@import './decorate.css';
@import './ai-rifm.css';
@import './medical-service.css';
@import './footer.css';
@import './arabic.css';
@import './override.css';

:root {
  --bezier: cubic-bezier(0.6, 0.2, 0.1, 1);
  --font-aktiv: 'aktiv-grotesk-thai';
}

/* scroll by # */
#content {
  scroll-margin-block-start: 56px;
}

html {
  scroll-behavior: smooth;
  @apply tw-bg-bgh-white;
}
body {
  @apply tw-max-w-[100vw] tw-overflow-x-hidden tw-bg-bgh-white;
}

main {
  min-height: 80vh;
  @apply tw-bg-bgh-white tw-max-w-full tw-relative;
}

main *,
main ::before,
main ::after {
  @apply tw-border-bgh-blue-alpha;
}

.menu-button {
  @apply hover:sm:!tw-bg-bgh-blue-alpha/50 md:!tw-p-4 md:!tw-rounded-md tw-cursor-pointer tw-duration-200 
   max-md:tw-py-6 max-sm:tw-py-4 max-md:tw-h-full max-md:tw-flex max-md:tw-items-center max-md:tw-justify-center;
}

.menu-chip {
  @apply hover:sm:!tw-bg-bgh-blue-alpha/50 !tw-bg-white !tw-rounded-md tw-cursor-pointer tw-duration-200  max-md:tw-flex max-md:tw-items-center max-md:tw-justify-center md:!tw-px-4 md:!tw-py-2 tw-px-3 tw-py-1.5 !tw-border tw-space-x-2 rtl:tw-space-x-reverse;
}

/* gradient */

.bg-gradient-blue {
  background: linear-gradient(
    180deg,
    rgba(0, 45, 115, 0),
    rgba(0, 45, 115, 0.05)
  );
}

.bg-gradient-blue-inset {
  background: linear-gradient(
    180deg,
    rgba(0, 45, 115, 0.05),
    rgba(0, 45, 115, 0) 23.88%,
    rgba(0, 45, 115, 0) 80.73%,
    rgba(0, 45, 115, 0.08)
  );
}

.bg-gradient-blue-top {
  background: linear-gradient(
    0deg,
    rgba(0, 45, 115, 0) 50%,
    rgba(0, 45, 115, 0.05) 100%
  );
}

@screen sm {
  .tw-shadow-main-dark-sm {
    box-shadow:
      0 2px 5px hsla(0, 0%, 60.8%, 0.1),
      0 0 30px -15px rgba(0, 102, 255, 0.2);
  }
}
.tw-shadow-main-dark {
  box-shadow:
    0 2px 5px hsla(0, 0%, 60.8%, 0.1),
    0 0 30px -15px rgba(0, 102, 255, 0.2);
}

.button-animate::before {
  position: absolute;
  content: '';
  top: 0;
  width: 20%;
  height: 100%;
  transform: skew(-45deg);
  transition: all 0.3s cubic-bezier(0.6, 0.2, 0.1, 1);
  background: linear-gradient(
    -34.83deg,
    rgba(216, 231, 255, 0.2) 17.64%,
    rgba(168, 202, 255, 0.05) 71.04%
  );
  left: -15%;
}
.button-animate::after {
  position: absolute;
  content: '';
  top: 0;
  width: 20%;
  height: 100%;
  transform: skew(-45deg);
  transition: all 0.3s cubic-bezier(0.6, 0.2, 0.1, 1);
  background: linear-gradient(
    34.83deg,
    rgba(216, 231, 255, 0.12) 17.64%,
    rgba(168, 202, 255, 0.05) 71.04%
  );
  right: -15%;
}
.button-animate:not(.-disabled):hover:before {
  left: -50%;
  top: -50%;
}
.button-animate:not(.-disabled):hover:after {
  right: -50%;
  top: 50%;
}

.absolute-center {
  @apply tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2;
}

.center-child {
  @apply tw-flex tw-items-center tw-justify-center;
}

.text-red-dash {
  position: relative;
  color: #002d73;
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 32px;
  font-size: 13px !important;
}

.text-red-dash:before {
  position: absolute;
  content: '';
  top: 40%;
  left: 0;
  width: 24px;
  height: 3px;
  border-radius: 4px;
  background-color: #b21e28;
}

.ellipse-bg {
  position: relative;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
  z-index: 0;
}

.ellipse-bg:after {
  content: '';
  background-image: url(https://static.bangkokhospital.com/uploads/2020/04/Ellipse-44.svg);
  background-repeat: no-repeat;
  position: absolute;
  top: 12px;
  left: 0;
  width: 100vw;
  background-size: contain;
  height: 100vh;
  z-index: -1;
  pointer-events: none;
}

.backdrop-filter {
  backdrop-filter: blur(5px);
  z-index: 101;

  &.-white {
    background: rgba(255, 255, 255, 0.8);
  }

  &.-more {
    backdrop-filter: blur(10px);
  }
}

.main-gradient {
  background: linear-gradient(
    90deg,
    #a00b1d 0%,
    #be000d 61.5%,
    #1b17de 90.45%,
    #003596 100%
  );
}

.main-gradient-border {
  border: double 4px transparent;
  border-radius: 16px;
  background-image: linear-gradient(
    90deg,
    #a00b1d 0%,
    #be000d 61.5%,
    #1b17de 90.45%,
    #003596 100%
  );
  background-origin: border-box;
}

.square-gradient-border {
  border: 5px solid;

  border-image-slice: 1;

    border-image-source: linear-gradient(23.79deg, rgba(28, 23, 221, 0.8) 1.91%, #B21E28 109.54%);
}

.bg-white-gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 45, 115, 0) 0%,
    rgba(0, 45, 115, 0.05) 80%,
    rgba(0, 45, 115, 0.05) 100%
  );
}

.bg-white-blur {
  background: linear-gradient(
    216deg,
    #fff -2.93%,
    rgba(255, 255, 255, 0.2) 56.85%,
    #fff 109.76%
  );
  backdrop-filter: blur(15px);
}

.skeleton-fill {
  @apply !tw-absolute !tw-w-full !tw-h-full !tw-transform-none;
}

.button-disabled {
  @apply !tw-grayscale tw-opacity-50 !tw-pointer-events-none;
}

.highlight-fields
  .MuiFormLabel-root:not(.MuiFormLabel-filled)
  + .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  @apply tw-text-bgh-blue-bright !tw-border-[0.2rem] !tw-border-bgh-blue-bright;
}

.highlight-fields .MuiFormLabel-root:not(.MuiFormLabel-filled) {
  @apply !tw-text-bgh-blue-bright;
}
.appoitnemnt-submit-button {
  animation: pulse 0.8s infinite;
}
.booked {
  @apply !tw-bg-bgh-blue [&>*]:!tw-text-white !tw-opacity-50 !tw-pointer-events-none;
}


