 .doctor-background {
     background-image: url('https://static.bangkokhospital.com/uploads/2024/09/doctor-profile-bg-mobile-2.png');
 }
 .doctor-background-vibrant {
    background-image: url('https://static.bangkokhospital.com/uploads/2024/05/doctor-profile-bg-mobile.png');
 }
 .education-wrapper{
    background:linear-gradient(34.83deg, rgba(0, 45, 115, .12) 17.64%, rgba(0, 45, 115, .02) 71.04%);
 }

 .inverted-border-radius::before{
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: -24px;
    left: 0;
    height: 24px;
    width: 24px;
    border-top-left-radius: 12px;
    box-shadow: 0 -12px 0 0 #fbfbfc;
    z-index:-10;
 }

 .inverted-border-radius:after {
     content: "";
     position: absolute;
     background-color: transparent;
     right: -59px;
     top: 0;
     height: 64px;
     width: 36px;
     border-top-left-radius: 16px;
     box-shadow: 0 -24px 0 0 #fbfbfc;
 }

 @screen sm {
     .doctor-background {
         background-image: url('https://static.bangkokhospital.com/uploads/2023/05/doctor-profile-bg-2.png') !important;
     }
    .doctor-background-vibrant {
        background-image: url('https://static.bangkokhospital.com/uploads/2024/05/doctor-profile-bg-1.png') !important;
    }
 }
  @screen md {
    .inverted-border-radius::before {
            border-top-left-radius: 24px;
            height: 48px;
            bottom: -48px;
            box-shadow: 0 -24px 0 0 #fbfbfc;
    }

    .inverted-border-radius:after {
        right: -183px;
        width: 144px;
        border-top-left-radius: 24px;
        height: 24px;
        box-shadow: -4px -12px 0 0 #fbfbfc;
    }
  }
