/* Thai */
[lang='th'] {
  body {
    font-family:
      var(--font-aktiv),
      var(--font-satoshi),
      var(--font-sarabun),
      BlinkMacSystemFont,
      -apple-system,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      sans-serif;
  }
  .text-button {
    @apply tw-font-[600];
  }
}
/* Other Languages */
html:not([lang='th']) {
  body {
    font-family:
      var(--font-satoshi),
      var(--font-aktiv),
      var(--font-sarabun),
      BlinkMacSystemFont,
      -apple-system,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      sans-serif;
  }
  .text-button {
    @apply tw-font-[750];
  }
}

/* Thai */
[lang='th'] h1,
[lang='th'] h2,
[lang='th'] h3,
[lang='th'] h4,
[lang='th'] h5,
[lang='th'] h6 {
  @apply tw-font-[700];
}
/* Other Languages */
html:not([lang='th']) h1,
html:not([lang='th']) h2 {
  font-family:
    var(--font-satoshiRealBold),
    -apple-system,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    sans-serif;
  @apply tw-font-[700];
}
html:not([lang='th']) h3,
html:not([lang='th']) h4,
html:not([lang='th']) h5,
html:not([lang='th']) h6 {
  @apply tw-font-[750];
}

/* Common */
html,
body {
  @apply tw-font-normal tw-text-base tw-text-bgh-dark;
}

p {
  line-height: 1.5;
}

h1,
h2 {
  @apply tw-text-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 130% !important;
  @apply tw-text-primary;
}

h1,
.size-page-title {
  @apply sm:!tw-text-4xl !tw-text-2xl;
}
h2,
.size-section-title {
  @apply tw-text-2xl sm:tw-text-[2.1rem] !tw-leading-snug;
}
h3,
.size-content-topic {
  @apply tw-text-xl sm:tw-text-2xl;
}
h4 {
  @apply tw-text-xl;
}
h5 {
  @apply sm:tw-text-lg tw-text-base;
}
h6 {
  @apply tw-text-base;
}

.text-title {
  @apply !tw-leading-tight tw-font-bold tw-text-primary tw-text-center;
}

.text-button {
  @apply tw-capitalize tw-text-[0.95rem]  xs:tw-text-[1.1rem] md:tw-text-lg;
}

.text-label {
  @apply tw-text-bgh-blue-light tw-text-xs tw-font-bold tw-uppercase tw-mb-2;
}

label.text-section-label {
  @apply tw-text-base tw-font-bold tw-mb-2 tw-text-bgh-gray;
}

.md-br br {
  @apply md:tw-block tw-hidden;
}

.no-br br {
  @apply tw-hidden;
}

/* .title-color-bar {
  position: relative;
  display: inline-block;
  z-index: 10;
  @apply sm:tw-mb-4 tw-mb-2 tw-duration-200;
}

.title-color-bar._desktop::before,
.title-color-bar._desktop::after {
  @apply max-sm:tw-opacity-0;
}

.title-color-bar._mobile::before,
.title-color-bar._mobile::after {
  @apply md:tw-opacity-0;
}

.title-color-bar::before {
  position: absolute;
  content: '';
  bottom: -10px;
  height: 4px;
  z-index: -1;
  background: #b21e28;
  left: 0;
  @apply md:tw-w-full sm:tw-w-[320px] tw-w-[150px] max-md:tw-left-[calc(50%-160px)]  max-sm:tw-left-[calc(50%-75px)] tw-duration-200;
}

.title-color-bar::after {
  position: absolute;
  content: '';
  bottom: -10px;
  height: 4px;
  z-index: -1;
  background: #002d73;
  right: 0;
  @apply sm:tw-w-[20%] tw-w-[40px] max-md:tw-right-[calc(50%-160px)] max-sm:tw-right-[calc(50%-75px)] tw-duration-200;
}

.title-color-bar.max-sm-short::before {
  @apply max-md:!tw-w-[70%] max-sm:!tw-w-[40%] max-md:!tw-left-0 max-sm:!tw-left-[20%];
}

.title-color-bar.max-sm-short::after {
  @apply max-md:!tw-w-[30%] max-sm:!tw-w-[20%] max-md:!tw-right-0 max-sm:!tw-right-[20%];
} */

.title-underline {
  display: inline !important;
  color: #002d73;
  background-image: linear-gradient(
    transparent calc(100% - 10px),
    #e7edff 10px
  );
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: background-size 1s;
  text-transform: capitalize;
  z-index: 0 !important;
}

.animated-underline {
  @apply tw-z-0 tw-text-primary tw-leading-tight tw-capitalize tw-inline-block tw-relative;
}

.animated-underline::before {
  content: '';
  background: linear-gradient(
    90deg,
    #a00b1d 0%,
    #be000d 61.5%,
    #1b17de 90.45%,
    #003596 100%
  );
  @apply tw-absolute -tw-bottom-3 tw-h-1 tw-z-[1] tw-duration-500 tw-ease-in-out !tw-w-[150px] !tw-left-[calc(50%-75px)];
  @screen sm {
    @apply tw-absolute -tw-bottom-2 tw-h-1 tw-z-[-1] tw-duration-700 tw-ease-in-out  !tw-w-full !tw-left-0;
  }
}

.animated-underline._mobile::before,
.animated-underline._mobile::after {
  @apply md:tw-opacity-0;
}

[lang='vn'] {
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', 'Helvetica', 'Arial', 'Segoe UI', 'Roboto', 'Oxygen',
      sans-serif;
  }
  .tw-capitalize {
    text-transform: none !important;
  }
}
