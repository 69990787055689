/* Related swiper */

.related-swiper {
  overflow: visible !important;
  padding-bottom: 64px !important;
}
.related-swiper .swiper-button-prev,
.related-swiper .swiper-button-next {
  transform: translateY(calc(-50%)) !important;
}

.other-topics-swiper {
  padding-bottom: 48px !important;
}

.roomrate-swiper {
  padding-bottom: 36px !important;
}
.appointment-swiper,
.network-filter-swiper,
.highlight-swiper,
.banner-swiper {
  overflow: visible !important;
}

.related-swiper .swiper-pagination-bullet,
.highlight-swiper .swiper-pagination-bullet,
.roomrate-swiper .swiper-pagination-bullet,
.other-topics-swiper .swiper-pagination-bullet {
  @apply !tw-bg-bgh-blue/60 md:!tw-size-2.5 !tw-size-2;
}
.banner-swiper .swiper-pagination-bullet {
  @apply md:!tw-bg-bgh-blue-fade/80 !tw-bg-bgh-blue-light/50  !tw-size-2;
}
.related-swiper .swiper-pagination-bullet-active,
.highlight-swiper .swiper-pagination-bullet-active,
.banner-swiper .swiper-pagination-bullet-active,
.roomrate-swiper .swiper-pagination-bullet-active,
.other-topics-swiper .swiper-pagination-bullet-active {
  @apply !tw-w-8;
}
.related-swiper .swiper-pagination,
.highlight-swiper .swiper-pagination,
.other-topics-swiper .swiper-pagination {
  @apply !tw-text-left !tw-z-10;
}
[lang='ar'] {
  .roomrate-swiper .swiper-pagination {
    @apply !tw-text-left !tw-z-10;
  }
}
.banner-swiper .swiper-pagination {
  @apply max-md:!tw-top-[calc(100%+4px)];
}

.other-topics-swiper .swiper-pagination {
  @apply !tw-text-left !tw-z-10 tw-px-4;
}

.swiper-button-next,
.swiper-button-prev {
  box-shadow:
    0 50px 50px rgba(0, 4, 12, 0.05),
    0 4px 15px rgba(0, 0, 0, 0.05),
    0 0 5px rgba(0, 4, 12, 0.05);
  backdrop-filter: blur(3px) !important;
  border: 1px solid rgba(255, 255, 255, 0.01);
  background-color: hsla(0, 0%, 100%, 0.5);
  @apply tw-backdrop-contrast-75 tw-backdrop-brightness-75 tw-backdrop-saturate-150 tw-backdrop-opacity-50;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  @apply tw-text-primary;
}

.related-swiper .swiper-button-next,
.highlight-swiper .swiper-button-next {
  @apply ltr:!tw-right-auto ltr:!tw-left-[calc(100%-36px)] rtl:!tw-left-auto rtl:!tw-right-[calc(100%-36px)] sm:tw-left-full md:tw-left-[calc(100%-24px)]  2xl:tw-left-[calc(100%-24px)];
}

.related-swiper.offset-pagination .swiper-button-next {
  @apply ltr:!tw-left-[calc(100%-64px)] rtl:!tw-right-[calc(100%-64px)];
}

.highlight-swiper .swiper-button-next {
  @apply !tw-top-auto !tw-bottom-[25%];
}

.related-swiper .swiper-button-prev,
.highlight-swiper .swiper-button-prev {
  @apply ltr:!tw-left-auto ltr:!tw-right-[calc(100%-36px)] rtl:!tw-right-auto rtl:!tw-left-[calc(100%-36px)] sm:tw-right-full md:tw-right-[calc(100%-24px)]  2xl:tw-right-[calc(100%-24px)];
}

.highlight-swiper.arabic-highlight-swiper .swiper-button-next {
  bottom: 72% !important;
}

.related-swiper.offset-pagination .swiper-button-prev {
  @apply ltr:!tw-right-[calc(100%-64px)] rtl:!tw-left-[calc(100%-64px)];
}

.highlight-swiper .swiper-pagination {
  @apply ltr:!tw-w-1/2 rtl:!tw-w-[55%] sm:!tw-text-right !tw-text-left sm:!tw-bottom-6 !-tw-bottom-8;
}

.appointment-swiper .swiper-button-next,
.network-filter-swiper .swiper-button-next {
  @apply tw-right-auto tw-left-[calc(100%-48px)] md:tw-left-[calc(100%-24px)];
}

.appointment-swiper .swiper-button-prev,
.network-filter-swiper .swiper-button-prev {
  @apply tw-left-auto tw-right-[calc(100%-48px)] md:tw-right-[calc(100%-24px)];
}

.related-swiper .swiper-button-next,
.related-swiper .swiper-button-prev,
.appointment-swiper .swiper-button-next,
.appointment-swiper .swiper-button-prev,
.highlight-swiper .swiper-button-next,
.highlight-swiper .swiper-button-prev,
.banner-swiper .swiper-button-next,
.banner-swiper .swiper-button-prev,
.roomrate-swiper .swiper-button-next,
.roomrate-swiper .swiper-button-prev {
  border-radius: 6px !important;
  box-shadow:
    0 50px 50px rgba(0, 4, 12, 0.05),
    0 4px 15px rgba(0, 0, 0, 0.05),
    0 0 5px rgba(0, 4, 12, 0.05) !important;
  @apply md:tw-flex tw-hidden;
}
.banner-swiper .swiper-button-next,
.banner-swiper .swiper-button-prev {
  @apply !tw-mt-0;
}

.network-filter-swiper .swiper-button-next,
.network-filter-swiper .swiper-button-prev {
  border-radius: 6px !important;
  box-shadow:
    0 50px 50px rgba(0, 4, 12, 0.05),
    0 4px 15px rgba(0, 0, 0, 0.05),
    0 0 5px rgba(0, 4, 12, 0.05) !important;
  @apply !tw-flex md:tw-hidden !tw-opacity-100 tw-mt-0;
}

.network-filter-swiper .swiper-button-disabled,
.network-filter-swiper.swiper:hover .swiper-button-disabled {
  @apply !tw-opacity-0;
}

.appointment-swiper.swiper:hover .swiper-button-next,
.appointment-swiper.swiper:hover .swiper-button-prev,
.roomrate-swiper.swiper:hover .swiper-button-next,
.roomrate-swiper.swiper:hover .swiper-button-prev {
  @apply !tw-opacity-100 tw-mb-10;
}

.related-swiper .swiper-button-next:after,
.related-swiper .swiper-button-prev:after,
.appointment-swiper .swiper-button-next:after,
.appointment-swiper .swiper-button-prev:after,
.network-filter-swiper .swiper-button-next:after,
.network-filter-swiper .swiper-button-prev:after,
.highlight-swiper .swiper-button-next:after,
.highlight-swiper .swiper-button-prev:after,
.banner-swiper .swiper-button-next:after,
.banner-swiper .swiper-button-prev:after,
.roomrate-swiper .swiper-button-next:after,
.roomrate-swiper .swiper-button-prev:after {
  font-size: 14px !important;
}

.network-filter-swiper .swiper-slide,
.network-filter-swiper .swiper-slide > div {
  @apply tw-w-auto tw-grow-0;
}

.network-filter-swiper .swiper-button-prev,
.network-filter-swiper .swiper-button-next {
  @apply !tw-top-1/2 !-tw-translate-y-1/2;
}

.roomrate-swiper .swiper-slide > div {
  @apply tw-duration-1000;
}
.highlight-swiper .swiper-slide #card-detail-wrapper {
  @apply tw-opacity-0 tw-delay-200 tw-duration-500;
}
.highlight-swiper .swiper-slide-prev #card-detail-wrapper {
  @apply -tw-translate-x-40;
}
.highlight-swiper .swiper-slide-next #card-detail-wrapper {
  @apply tw-translate-x-40;
}

.highlight-swiper .swiper-slide-active #card-detail-wrapper {
  @apply !tw-translate-x-0 !tw-opacity-100;
}

.highlight-swiper .swiper-slide #card-detail-background {
  @apply tw-delay-300 tw-duration-500;
}
.highlight-swiper .swiper-slide-prev #card-detail-background {
  @apply -tw-translate-x-80;
}
.highlight-swiper .swiper-slide-next #card-detail-background {
  @apply tw-translate-x-80;
}

.highlight-swiper .swiper-slide-active #card-detail-background {
  @apply !tw-translate-x-0;
}
/* 
.highlight-swiper .swiper-slide-active #card-detail-background {
    @apply ;
}
     */

/* about-swiper */
.about-swiper.swiper-container,
.other-topics-swiper.swiper-container,
.related-award-swiper.swiper-container {
  @apply md:tw-p-0 tw-p-4 md:tw-my-0 tw--my-4;
}

/* scroll bar */

.swiper-step {
  .scroll-part {
    position: relative;
  }

  .scroll-part::after {
    content: '';
    position: absolute;
    height: 8px;
    width: 4px;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 6px;
    z-index: 10;
  }

  .swiper-scrollbar-step {
    margin: auto;
    width: 100%;
    max-width: 500px;
    height: 14px;
    background: #ededed;
    border-radius: 14px;
  }

  .swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    left: 0;
    top: 0;
    z-index: 20;
  }

  .swiper-scrollbar-drag {
    background: none;
  }

  .swiper-scrollbar-drag:after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100px;
    border: 2px solid #bebebe;
    background: #fff;
    cursor: pointer;
  }
  .entry-content {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: translateX(-50%);
    margin-left: 50%;
  }
  .image-step {
    box-shadow: 0 3px 24px rgba(0, 0, 0, 0.12);
    transform: scale(0.8);
    transform-origin: center;
    outline: transparent solid 8px !important;
    transition: all 0.3s ease-in-out;
  }

  .swiper-slide-active {
    .slide-indicator {
      opacity: 1 !important;
      transform: scale(1) !important;
    }

    .entry-content {
      opacity: 1 !important;
    }

    .image-step {
      outline: white solid 8px !important;
      transform: scale(1) !important;
    }
  }

  .slide-indicator {
    opacity: 0;
    transition: all 300ms ease-in-out;
    transform: scale(0);
  }
}
.swiper-step::after {
  position: absolute;
  content: '';
  top: 0px;
  left: -80%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #fbfbfc 50%, transparent 100%);
  z-index: 10;
  pointer-events: none;
}

.swiper-step::before {
  position: absolute;
  content: '';
  top: 0px;
  right: -80%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent 0%, #fbfbfc 50%);
  z-index: 10;
  pointer-events: none;
}
