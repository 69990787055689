/* AI rifm */
.ai-rifm-chip-shadow {
    box-shadow: 0px 0px 16px 0px rgba(57, 135, 244, 0.35);
  }
.ai-rifm-chip-shadow-animate {
  box-shadow: 0px 0px 16px 0px rgba(57, 135, 244, 0.35);
  animation: voicePlaying 1.5s infinite ease-in-out;
}
@keyframes voicePlaying {
  0% {
    box-shadow: 0px 0px 16px 0px rgba(57, 135, 244, 0.35);
  }

  25% {
    box-shadow: 0px 0px 24px 2px rgba(57, 125, 244, 0.45);
  }

  50% {
    box-shadow: 0px 0px 12px 0px rgba(56, 189, 241, 0.3);
  }

  75% {
    box-shadow: 0px 0px 20px 2px rgba(56, 135, 244, 0.4);
  }

  100% {
    box-shadow: 0px 0px 16px 0px rgba(57, 135, 244, 0.35);
  }
}






.ai-rifm-text-shine {
  background: linear-gradient(to right,
      #3cd8f5 20%,
      #2c97ee 40%,
      #1b72e7 50%,
      #1260e1 60%,
      #2c97ee 70%,
      #3cd8f5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 3s linear infinite;
}

@keyframes textShine {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 200% 50%;
  }
}