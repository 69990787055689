.area {
    position: fixed;
    width: 100%;
    overflow: hidden;
    height: 60vh;
    bottom: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
    background: linear-gradient(0,
            rgba(218, 222, 240, 0.5) 0,
            rgba(255, 255, 255, 0) 60%);
}

.circles {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 10px;
    height: 10px;
    border-radius: 8px;
    animation: animate 8s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    @apply tw-bg-bgh-blue-alpha;
    left: 25%;
    width: 40px;
    height: 40px;
    animation-delay: 0s;
    background-image: none !important;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 10px;
    height: 10px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    @apply tw-bg-bgh-blue-alpha;
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
    background-image: none !important;
}

.circles li:nth-child(4) {
    @apply tw-bg-bgh-blue-alpha;
    left: 40%;
    width: 30px;
    height: 30px;
    animation-delay: 0s;
    background-image: none !important;
    animation-duration: 15s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 10px;
    height: 10px;
    animation-delay: 0s;
    background-image: none !important;
}

.circles li:nth-child(6) {
    left: 90%;
    width: 60px;
    height: 60px;
    opacity: 0.5;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 5%;
    width: 70px;
    height: 70px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 60%;
    width: 35px;
    height: 35px;
    animation-delay: 15s;
    animation-duration: 10s;
}

.circles li:nth-child(9) {
    left: 45%;
    width: 18px;
    height: 18px;
    animation-delay: 2s;
    background-image: none !important;
}

@keyframes animate {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-60vh);
        opacity: 0;
    }
}


.ai-badge-glow {
    position: relative;

    &::before {
        position: absolute;
        pointer-events: none;
        content: '';
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        z-index: 0 !important;
        filter: blur(20px);

        transform: translateZ(0);
        border-radius: 9999px;
        background: linear-gradient(to left,
                #404dff,
                #0077ff,
                #0088ff,
                #39bff4,
                #0088ff,
                #0077ff,
                #404dff);
        background-size: 200% 200%;
        animation: animateGlow 1.25s linear infinite;
    }

    &::after {
        position: absolute;
        pointer-events: none;
        content: '';
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        z-index: 0 !important;
        opacity: 0.1;
        transform: translateZ(0);
        border-radius: 9999px;
        background: linear-gradient(to left,
                #404dff,
                #0077ff,
                #0088ff,
                #39bff4,
                #0088ff,
                #0077ff,
                #404dff);
        background-size: 200% 200%;
        animation: animateGlow 1.25s linear infinite;
    }
}

@keyframes animateGlow {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 200% 50%;
    }
}

