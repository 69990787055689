.to-top-wrapper {
    transition: all 0.5s cubic-bezier(0.6, 0.2, 0.1, 1);
    bottom: 63px;
    z-index: 3;

    &.special-page {
        bottom: 95px;
    }

    ._bg {
        @apply tw-border-bgh-blue-alpha;
        background: rgba(255, 255, 255, 0.35);
        border: 1px solid;
        border-right: none !important;
        z-index: 3;
        position: relative;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
    }

    .to-top {
        opacity: 0;
        pointer-events: none;
        bottom: -30px;
        transition: all 0.5s cubic-bezier(0.6, 0.2, 0.1, 1);

        &.-is-sticky {
            opacity: 1;
            bottom: 0px;
            pointer-events: auto;
        }
    }

    .contacts-button {
        @apply tw-bg-primary;
     
        padding: 7px;
        padding-right: 8px;
        opacity: 0;
        right: -100%;
        pointer-events: none;
        color: white;
        transition: all 0.5s cubic-bezier(0.6, 0.2, 0.1, 1);

        &.-is-sticky {
            opacity: 1;
            right: 0px;
            pointer-events: auto;
        }
    }
}

._w-icon {
    @apply md:!tw-w-[62px] !tw-w-[55px];
}

._h-icon {
    @apply md:!tw-h-[62px] !tw-h-[55px];
}