[lang='ar'],
[dir='rtl'] {
  .tw-space-x-1,
  .tw-space-x-2,
  .tw-space-x-3,
  .tw-space-x-4,
  .tw-space-x-6 {
    @apply tw-space-x-reverse;
  }
  .MuiChip-icon {
    @apply !tw-mr-0 !tw-ml-1;
  }
  .MuiInputLabel-animated {
    @apply !tw-right-8 !tw-origin-top-right;
  }
  .MuiInputLabel-shrink {
    @apply !tw-right-0 !tw-left-auto !tw-origin-top-right;
    transform: translate(-16px, -9px) scale(0.75) !important;
  }
  .MuiOutlinedInput-notchedOutline {
    legend {
      @apply !tw-ml-auto !tw-text-right;
    }
  }
  input[type='tel'] {
    direction: rtl;
  }
  .MuiInputBase-adornedStart {
    @apply !tw-pl-0 !tw-pr-3.5;
  }
  .MuiInputBase-adornedEnd {
    /* @apply !tw-flex-row-reverse; */
  }
  .MuiSelect-outlined {
    @apply !tw-pr-4;
  }
  .MuiAutocomplete-endAdornment {
    @apply !tw-right-auto !tw-left-1;
  }
  .MuiInputBase-adornedEnd i:not(.autocomplete-chevron-down) {
    @apply !tw-px-4;
  }
  .MuiStepConnector-root {
    left: calc(50% + 20px) !important;
    right: calc(-50% + 20px) !important;
  }
  .MuiRadioGroup-root .MuiFormControlLabel-label {
    padding-right: 3rem !important;
    padding-left: 1.1rem !important;
  }
  .MuiAlert-icon {
    @apply !tw-mr-0 !tw-ml-4;
  }
  .MuiPickersArrowSwitcher-root {
    @apply !tw-flex-row-reverse;
  }
  .tw-divide-x {
    @apply tw-divide-x-reverse;
  }
  .swiper-pagination {
    @apply !tw-text-right;
  }
  /* arabic page */
  .arabic-banner-swiper {
    .swiper-pagination {
      @apply !tw-text-center;
    }
  }
  .arabic-highlight-swiper {
    .swiper-pagination {
      bottom: -40px !important;
      @apply !tw-w-full max-sm:!tw-text-center max-sm:!-tw-bottom-6;
    }
  }

  /* snackbar */
  .go703367398 {
    @apply !tw-pl-0 !tw-pr-4 !-tw-ml-2 !tw-mr-auto;
  }

  /* gradient */
  .bg-gradient-arabic-blue {
    background: linear-gradient(
      90deg,
      hsla(214, 73%, 36%, 1) 0%,
      hsla(226, 93%, 12%, 1) 100%
    );
  }
  .bg-gradient-arabic-blue-button {
    background: linear-gradient(95.57deg, #002d73 -82.37%, #0147a3 96.95%);
  }

  .bg-gradient-arabic-blue-radial {
    background: linear-gradient(180deg, #bdd7ff -60.43%, #002d73 100%),
      radial-gradient(
        98.51% 75.23% at -31.97% 0%,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }

  .bg-gradient-arabic-blue-fade {
    background: linear-gradient(
      -270deg,
      rgba(0, 45, 115, 0) -1.85%,
      #000000 182.65%
    );
  }

  .bg-gradient-arabic-blue-light {
    background: linear-gradient(347deg, #ebf1f7 2%, #ffffff 30%);
  }

  /* shadow */
  .shadow-top-arabic-blue {
    box-shadow: 0px -23px 60.4px -4px rgba(0, 45, 115, 0.89);
  }
  .shadow-section-arabic-blue {
    box-shadow: 0px 10px 47.1px 0px rgba(0, 85, 217, 0.15);
  }

  .text-gradient-arabic {
    background: linear-gradient(267.68deg, #b21e28 -17.07%, #0055d9 87.9%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
