/* ticket */
[dir="rtl"]{
    .ticket-mask {
             mask-image: radial-gradient(circle at 0% 50%, transparent 5%, black 5.1%);
             -webkit-mask-image: radial-gradient(circle at 0% 50%, transparent 5%, black 5.1%);
         }
    @screen xs {
        .ticket-mask {
            mask-image: radial-gradient(circle at 0% 50%, transparent 3%, black 3.1%);
            -webkit-mask-image: radial-gradient(circle at 0% 50%, transparent 3%, black 3.1%);
        }
    }
    @screen sm {
        .ticket-mask {
            mask-image: radial-gradient(circle at 0% 50%, transparent 5%, black 5.1%);
            -webkit-mask-image: radial-gradient(circle at 0% 50%, transparent 5%, black 5.1%);
        }
    }
}
[dir="ltr"]{
    .ticket-mask {
            mask-image: radial-gradient(circle at 100% 50%, transparent 5%, black 5.1%);
            -webkit-mask-image: radial-gradient(circle at 100% 50%, transparent 5%, black 5.1%);
        }
    
        @screen xs {
            .ticket-mask {
                mask-image: radial-gradient(circle at 100% 50%, transparent 3%, black 3.1%);
                -webkit-mask-image: radial-gradient(circle at 100% 50%, transparent 3%, black 3.1%);
            }
        }
    
        @screen sm {
            .ticket-mask {
                mask-image: radial-gradient(circle at 100% 50%, transparent 5%, black 5.1%);
                -webkit-mask-image: radial-gradient(circle at 100% 50%, transparent 5%, black 5.1%);
            }
        }
}
/* gradient */
.text-gradient-blue {
    background: linear-gradient(103deg, #0147A3 9.15%, #99BFF6 53.1%, #0147A3 85.27%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.bg-gradient-fpd-blue {
    background: linear-gradient(97deg, #EAF3FF -19.12%, #82B6FF 117.03%);
}

.bg-gradient-fpd-blue-main {
    background: linear-gradient(94deg, #5196F7 1.17%, #295FEB 100%);
}

.bg-gradient-fpd-gray {
    background: linear-gradient(95deg, #778499 -1.09%, #204D71 104.29%);
}
/* mask shadow */
.ticket-mask-shadow{
    @apply tw-relative tw-z-10 tw-overflow-visible;
}

.ticket-mask-shadow::before {
    content: '';
    background: radial-gradient(circle, rgba(0, 102, 255, 0.07) 0%, rgba(121, 153, 201, 0.05) 40%, rgba(0, 212, 255, 0) 80%);
    /* background: radial-gradient(circle, rgba(0, 102, 255, 0.05) 0%, rgba(121, 153, 201, 0.10) 40%, rgba(0, 212, 255, 0) 70%);    */
    @apply  tw-absolute tw-w-40 tw-h-0 tw-pb-40 tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-z-0  sm:tw-scale-x-[300%] tw-scale-x-[500%];
    
}
/* snackbar */
.snackbar-close-icon{
    @apply tw-cursor-pointer tw-h-full ltr:tw-pr-2.5 rtl:tw-pl-3 ltr:tw-pl-3 rtl:tw-pr-3 tw-py-2 ltr:tw-border-l rtl:tw-border-r tw-border-white/50;
}


