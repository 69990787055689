.container-fluid {
  width: 100%;
  max-width: 1440px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.tw-container {
  @apply 2xl:tw-max-w-[1240px] md:tw-max-w-[1080px] max-md:tw-max-w-[92%] max-sm:tw-max-w-[100%];
}

.page-wrapper {
  @apply sm:tw-pt-8 tw-pt-4 md:tw-pb-24 tw-pb-16 tw-relative tw-flex tw-flex-col tw-items-center sm:tw-space-y-12 tw-space-y-8;
}

.page-wrapper.unset {
  align-items: unset !important;
}

.triangle-top {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 32vw;
  height: 32vw;
  background-image: url('https://static.bangkokhospital.com/uploads/2020/04/triangle.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
}

.triangle-bottom {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width: 32vw;
  height: 32vw;
  background-image: url('https://static.bangkokhospital.com/uploads/2020/04/triangle.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  transform: scaleY(-1) scaleX(-1);
  z-index: 0;
}
.triangle-top-card {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 20vw;
  height: 20vw;
  background-image: url('https://static.bangkokhospital.com/uploads/2020/04/triangle.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
}

.triangle-bottom-card {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width: 20vw;
  height: 20vw;
  background-image: url('https://static.bangkokhospital.com/uploads/2020/04/triangle.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  transform: scaleY(-1) scaleX(-1);
  z-index: 0;
}
@screen sm {
  .triangle-top-card {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 10vw;
    height: 10vw;
    background-image: url('https://static.bangkokhospital.com/uploads/2020/04/triangle.svg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 0;
  }

  .triangle-bottom-card {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 10vw;
    height: 10vw;
    background-image: url('https://static.bangkokhospital.com/uploads/2020/04/triangle.svg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    transform: scaleY(-1) scaleX(-1);
    z-index: 0;
  }
}

.mui-modal {
  @apply tw-absolute tw-top-1/2 tw-left-1/2 tw-max-h-[92vh] tw-w-full 2xl:tw-max-w-[1240px] tw-max-w-[97vw] -tw-translate-x-1/2 -tw-translate-y-1/2 tw-overflow-y-auto tw-bg-white sm:tw-p-6 tw-px-4 tw-pt-4 tw-pb-8 sm:tw-min-w-[560px] sm:tw-rounded-lg tw-rounded-md;
}

.full-width-mobile {
  @apply max-md:tw-w-[100vw] max-md:tw-relative max-md:tw-left-1/2 max-md:tw-right-1/2 max-md:tw--mx-[50vw];
}

.filter-container {
  @apply sm:tw-max-w-[740px] tw-w-full tw-mx-auto tw-container;
}
